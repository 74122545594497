<template>
  <main class="main">
    <section class="section">
      <div class="site-container">
        <div v-if="error.length > 0" class="info-block info-block--alert">
          <h3>Внимание</h3>
          <p v-if="false">Мы работаем только с юридическими лицами и индивидуальными предпринимателями</p>
          <p v-for="item in error">{{item}}</p>
        </div>

        <form class="auth-reg__form form" @submit.prevent="registration">
          <div class="form__input-group">
            <h2 class="form__input-group-heading form__input-group-heading--required">Обязательные для заполнения поля</h2>
            <div class="form__input-block">
              <label class="form__input-label">
                Наименование точки продажи<span class="form__input-required"> *</span>
              </label>
              <div class="form__input-wrapper">
                <input v-model="name" required class="form__input input" type="text" placeholder="Введите название бара/иного заведения">
              </div>
            </div>

            <div class="form__input-block">
              <label class="form__input-label">
                ИНН<span class="form__input-required"> *</span>
              </label>
              <div class="form__input-wrapper">
                <input v-model="inn" required class="form__input input" type="number" placeholder="Введите данные">
              </div>
            </div>
          </div>
          <div class="form__input-group">
            <template v-for="(item, index) in priem" :key="item.id">

                <button v-if="index>0" class="form__input-group-remove" type="button" @click="deletePoint(index)" :key="item.id">Удалить</button>
            <div class="form__input-block">
              <label class="form__input-label">
                Адрес точки продажи <span class="form__input-required"> *</span>
              </label>
              <div class="form__input-wrapper">
                <textarea v-model="item.address" required :key="item.id" class="form__input form__input--textarea textarea textarea--small" placeholder="Введите данные"></textarea>
              </div>
            </div>



            </template>
          <div class="form__input-block">
            <label class="form__input-label">
              Приём продукции<span class="form__input-required"> *</span>
            </label>
            <div class="form__input-wrapper">
              <Select2 class="form__input--half" v-model="priem_from" :options="from_options"  @select="mySelectEvent($event)" placeholder="с --:--" :settings="{minimumResultsForSearch: -1, dropdownPosition: 'below', width: '100%'}" />

              <Select2 class="form__input--half" v-model="priem_to" :options="to_options" placeholder="до --:--" :settings="{minimumResultsForSearch: -1, dropdownPosition: 'below', width: '100%'}" />
            </div>
          </div>
          </div>

          <div class="form__input-group">

            <div class="form__input-block">
              <label class="form__input-label" for="formInput5">
                ФИО контактного лица<span class="form__input-required"> *</span>
              </label>
              <div class="form__input-wrapper">
                <input v-model="fio" required class="form__input input" type="text" name="FIO" id="formInput5" placeholder="Введите данные">
              </div>
            </div>

            <div class="form__input-block">
              <label class="form__input-label" for="formInput6">
                Контактный телефон<span class="form__input-required"> *</span>
              </label>
              <div class="form__input-wrapper">
                <InputMask  v-model="phone" mask="+7 (999) 999-99-99" required class="form__input input" type="text" name="PHONE" id="formInput6" placeholder="+7 (___) ___-__-__" />
              </div>
            </div>

            <div class="form__input-block">
              <label class="form__input-label" for="formInput7">
                E-mail<span class="form__input-required"> *</span>
              </label>
              <div class="form__input-wrapper">
                <input class="form__input input" type="email"  v-model="email" required id="formInput7"  placeholder="Введите данные">
              </div>
            </div>
          </div>

          <div class="form__input-group form__input-group--non-required">
            <h2 class="form__input-group-heading form__input-group-heading--non-required">Необязательные поля</h2>

            <button class="form__group-toggle btn btn--non-target" type="button"
                    :class="tabur?'form__group-toggle--open':''"
                    @click="tabur=!tabur"
            >Данные юридического лица</button>

            <div class="form__group-minimized" v-show="tabur" style="display: none;">
              <div class="form__input-block">
                <label class="form__input-label" for="formInput120">
                  Наименование юридического лица
                </label>
                <div class="form__input-wrapper">
                  <textarea v-model="company" class="form__input form__input--textarea textarea textarea--small" name="YUR_NAME" id="formInput120" placeholder="Введите данные"></textarea>
                </div>
              </div>

              <div class="form__input-block">
                <label class="form__input-label" for="formInput12">
                  Юридический адрес
                </label>
                <div class="form__input-wrapper">
                  <textarea v-model="uraddress" class="form__input form__input--textarea textarea textarea--small" name="YUR_ADDRESS" id="formInput12" placeholder="Введите данные"></textarea>
                </div>
              </div>

              <div class="form__input-block">
                <label class="form__input-label" for="formInput13">
                  КПП
                </label>
                <div class="form__input-wrapper">
                  <input v-model="kpp" class="form__input input" type="text" name="KPP" id="formInput13" placeholder="Введите данные">
                </div>
              </div>

              <div class="form__input-block">
                <label class="form__input-label" for="formInput14">
                  ОГРН
                </label>
                <div class="form__input-wrapper">
                  <input v-model="ogrn" class="form__input input" type="text" name="OGRN" id="formInput14" placeholder="Введите данные">
                </div>
              </div>

              <div class="form__input-block">
                <label class="form__input-label" for="formInput15">
                  ОКПО
                </label>
                <div class="form__input-wrapper">
                  <input v-model="okpo" class="form__input input" type="text" name="OKPO" id="formInput15" placeholder="Введите данные">
                </div>
              </div>

              <div class="form__input-block">
                <label class="form__input-label" for="formInput16">
                  ID ЕГАИС
                </label>
                <div class="form__input-wrapper">
                  <input v-model="egais" class="form__input input" type="text" name="ID_EGAIS" id="formInput16" placeholder="Введите данные">
                </div>
              </div>
            </div>
          </div>

          <div class="form__input-group form__input-group--non-required">
            <button class="form__group-toggle btn btn--non-target" type="button"
                    :class="tabbill?'form__group-toggle--open':''"
                    @click="tabbill=!tabbill"
            >Расчетный счет</button>

            <div class="form__group-minimized" v-show="tabbill" style="display: none;">
              <div class="form__input-block">
                <label class="form__input-label" for="formInput17">
                  БИК
                </label>
                <div class="form__input-wrapper">
                  <input v-model="bik" class="form__input input" type="text" name="BIK" id="formInput17" placeholder="Введите данные">
                </div>
              </div>

              <div class="form__input-block">
                <label class="form__input-label" for="formInput18">
                  Банк
                </label>
                <div class="form__input-wrapper">
                  <input v-model="bank" class="form__input input" type="text" name="BANK" id="formInput18" placeholder="Введите данные">
                </div>
              </div>

              <div class="form__input-block">
                <label class="form__input-label" for="formInput19">
                  К/с
                </label>
                <div class="form__input-wrapper">
                  <input v-model="ks" class="form__input input" type="text" name="KS" id="formInput19" placeholder="Введите данные">
                </div>
              </div>

              <div class="form__input-block">
                <label class="form__input-label" for="formInput20">
                  Р/с
                </label>
                <div class="form__input-wrapper">
                  <input v-model="rs" class="form__input input" type="text" name="RS" id="formInput20" placeholder="Введите данные">
                </div>
              </div>
            </div>
          </div>

          <div class="form__input-group form__input-group--non-required">
            <button class="form__group-toggle btn btn--non-target" :class="tabdocs?'form__group-toggle--open':''" type="button" @click="tabdocs=!tabdocs">Документы</button>

            <div class="form__group-minimized" v-show="tabdocs">
              <div class="form__input-block">
                <input class="form__input form__input--file hidden-input" type="file" id="files" ref="files" multiple v-on:change="handleFileUploads( $event )">
                <a v-for="(file, index) in files" :key="file.name" class="form__uploaded-file">
                  {{ file.name }}<span @click="delFile(file.name)"></span></a>
                <label class="form__input-label form__input-label--file form__add-btn form__add-btn--files btn btn--non-target" for="files">
                  Загрузить файлы
                </label>
                <div class="form__files-text">
                  <p>
                    Загрузите файлы, которые у вас есть. Желательный список документов:
                  </p>
                  <ol>
                    <li>карта контрагента (с обязательным указанием юридического адреса и фактического),</li>
                    <li>свидетельство ОГРН (ОГРИП), </li>
                    <li>свидетельство о постановке на учёт в налоговый орган (ИНН),</li>
                    <li>документы подтверждающие полномочия на подписания договора (доверенность, приказ на директора).</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div class="form__input-group form__input-group--non-required form__input-group--no-border">
            <div class="form__input-block">
              <label class="form__input-label" for="formInput22">
                Комментарий
              </label>
              <div class="form__input-wrapper">
                <textarea v-model="comment" class="form__input form__input--textarea textarea" name="COMMENT" id="formInput22" placeholder="Напишите, что нам нужно знать"></textarea>
              </div>
            </div>

            <div class="form__input-block">
              <input v-model="agree_offerta" class="form__input form__input--checkbox hidden-input" type="checkbox" id="formInput23">
              <label class="form__input-label form__input-label--checkbox" for="formInput23">
                Я согласен с Договором оферты
              </label>
            </div>

            <div class="form__input-block">
              <input v-model="agree_politics" class="form__input form__input--checkbox hidden-input" type="checkbox" id="formInput24">
              <label class="form__input-label form__input-label--checkbox" for="formInput24">
                Я согласен с <a target="_blank" href="https://docs.google.com/document/d/1m0Jdk6L-_8CbGjmGsMr3ArVNt9qs8VzTG7yCrohS5bQ/edit">Политикой конфиденциальности</a>
              </label>
            </div>

            <button class="form__submit-btn btn btn--alt" type="submit">Оставить заявку</button>
          </div>
        </form>
      </div>
    </section>
  </main>
</template>

<script>
import axios from "axios";
import Select2 from 'vue3-select2-component';
import InputMask from 'primevue/inputmask';

export default {
  data: () => ({
    email: '',
    password: '',
    password_confirm: '',
    phone: '',
    fio: '',
    inn: '',
    name: '',
    address: '',
    uraddress: '',
    ogrn: '',
    okpo: '',
    bik: '',
    bank: '',
    ks: '',
    rs: '',
    egais: '',
    comment: '',
    kpp: '',
    company: '',
    priem_from: '',
    priem_to: '',
    priem: [
      {
        id: 'n'+Date.now(),
        address: '',
        from: '',
        to: ''
      }
    ],
    files: [],
    error: [],
    tabdocs: false,
    tabur: false,
    tabbill: false,
    agree_offerta: false,
    agree_politics: false,
    from_options: ['10:00', '11:00', '12:00','13:00', '14:00', '15:00','16:00', '17:00', '18:00','19:00', '20:00', '21:00'],
    to_options_orig: ['11:00', '12:00','13:00', '14:00', '15:00','16:00', '17:00', '18:00','19:00', '20:00', '21:00', '22:00'],
    to_options: ['11:00', '12:00','13:00', '14:00', '15:00','16:00', '17:00', '18:00','19:00', '20:00', '21:00', '22:00'] // or [{id: key, text: value}, {id: key, text: value}]
  }),
  components: {Select2, InputMask},
  methods: {
    registration() {
      this.error = [];
      if (this.agree_offerta == false)
        this.error.push('Необходимо согласиться с Договором офферты');
      if (this.agree_politics == false)
        this.error.push('Необходимо согласиться с Политикой конфиденциальности');
      if (this.error.length > 0) {
        $(window).scrollTop($(".info-block--alert").offset().top);
        return;
      }
      let formData = new FormData();
      formData.append('email', this.email);
      formData.append('password', this.password);
      formData.append('password_confirm', this.password_confirm);
      formData.append('personal_phone', this.phone);
      formData.append('fio', this.fio);
      formData.append('inn', this.inn);
      formData.append('name', this.name);
      formData.append('kpp', this.kpp);
      formData.append('ogrn', this.ogrn);
      formData.append('okpo', this.okpo);
      formData.append('egais', this.egais);
      formData.append('bik', this.bik);
      formData.append('bank', this.bank);
      formData.append('ks', this.ks);
      formData.append('rs', this.rs);
      formData.append('uraddress', this.uraddress);
      formData.append('company', this.company);
      formData.append('comment', this.comment);
      formData.append('priem_from', this.priem_from);
      formData.append('priem_to', this.priem_to);
      for( var i = 0; i < this.priem.length; i++ ){
        let priem = this.priem[i];
        formData.append('priem[' + i + '][id]', priem.id);
        formData.append('priem[' + i + '][address]', priem.address);
      }
      for( var i = 0; i < this.files.length; i++ ){
        let file = this.files[i];
        formData.append('docs[' + i + ']', file);
      }
      let _this = this;
      axios.post( '/local/api/registration',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then(function(res){
        console.log(res)
        console.log('SUCCESS!!');
        if (res.data.error) {
          _this.error = res.data.error;
          $(window).scrollTop($(".info-block--alert").offset().top);
        }
        if (res.data.status == 200) {
          _this.$router.push('/registration-complete');
        }
      })
          .catch(function(){
            console.log('FAILURE!!');
          });
    },
    handleFileUploads(event){
      for( var i = 0; i < event.target.files.length; i++ ){
        if (typeof event.target.files[i] == 'object') {
          this.files.push(event.target.files[i]);
        }
      }
    },
    delFile(name){
      for( var i = 0; i < this.files.length; i++ ){
        if (typeof this.files[i] == 'object' &&  this.files[i].name == name) {
          this.files.splice(i, 1);
        }
      }
    },
    addPoint() {
      this.priem.push({
        id: 'n'+Date.now(),
        address: '',
        from: '',
        to: ''
      });
    },
    deletePoint(index) {
      this.priem.splice(index, 1);
    },
    mySelectEvent(event) {
      let to_options = [];
      for (let item in this.to_options_orig) {
                if (this.to_options_orig[item] > event.text === true)
          to_options.push(this.to_options_orig[item])
      }
      this.to_options = to_options;
    }
  },
  updated() {
    /*let formSelects = $('.form__input--select:not(.select2-hidden-accessible)');

    if (formSelects.length) {
  modifySelect2();

      formSelects.each(function() {
        let select = $(this),
            placeholder = select.data('placeholder');
        select.select2({
          'placeholder': placeholder,
          'minimumResultsForSearch': -1,
          'dropdownPosition': 'below',
          'width': '100%',
        });
      });
    }*/
  }
}
</script>

